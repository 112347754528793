import MediaBlock from "../components/mediaBlock";
import React from 'react';
import randomImage from "../randomImage";
import settings from "../settings"

export default class Resource extends React.Component {
    render() {
        let src = this.props.src || randomImage();
        return (
            <MediaBlock src={src} square={true}>
                <h3>{this.props.title}</h3>
                {this.props.children}

                <p><a href={this.props.href} target="_blank" rel="noopener noreferrer" className="usa-button cta">
                    <img src={settings.root +"assets/img/external-link-alt.png"} alt="" ></img>
                    {this.props.link}
                </a></p>
            </MediaBlock>
        )
    }
}

