import React from 'react';
import settings from "../settings"
export default class OfficialBanner extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            expanded:false
        }
        this.expand = this.expand.bind(this);
    }
    expand()
    {
        console.log("got here");
        this.setState( (e) =>{
            return {
                expanded: !e.expanded
            }
        })
    }
    render() {
        // let id = require("uuid").v4();
        let expanded = this.state.expanded;
        let style = {
            display:"block"
        }
        if(!expanded)
            style.display = "none";
        return (
            <section className="usa-banner">
                <div className="usa-accordion">
                    <header className="usa-banner-header">
                        <div className="usa-grid usa-banner-inner">
                            <img src={settings.root +"assets/img/favicons/favicon-57.png"} alt="U.S. flag"></img>
                            <p>An official website of the United States government</p>
                            <button className="usa-accordion-button usa-banner-button" aria-expanded="false" onClick={this.expand} aria-controls="gov-banner">
                                <span className="usa-banner-button-text">Here's how you know</span>
                            </button>
                        </div>
                    </header>
                    <div className="usa-banner-content usa-grid usa-accordion-content usa-grid " id="gov-banner" aria-hidden="false" style={style}>
                        <div className="usa-banner-guidance-gov usa-width-one-half">
                            <img className="usa-banner-icon usa-media_block-img" src={settings.root +"assets/img/icon-dot-gov.svg"} alt="Dot gov"></img>
                            <div className="usa-media_block-body">
                                <p>
                                    <strong>The .gov means it’s official.</strong>
                                    <br></br>
                                    Federal government websites often end in .gov or .mil. Before sharing sensitive
                                    information, make sure you’re on a federal government site.
                                </p>
                            </div>
                        </div>
                        <div className="usa-banner-guidance-ssl usa-width-one-half">
                            <img className="usa-banner-icon usa-media_block-img" src={settings.root +"assets/img/icon-https.svg"} alt="Https"></img>
                            <div className="usa-media_block-body">
                                <p>
                                    <strong>The site is secure.</strong>
                                    <br></br>
                                    The <strong>https://</strong> ensures that you are connecting to the official website and
                                    that any information you provide is encrypted and transmitted securely.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

