import Home from "../components/home";
import MarkLink from '../components/markLink';
import React from 'react';
import Hero from "../components/hero";
import Header from "../components/header";
const ReactMarkdown = require('react-markdown');
export default class Sitemap extends React.Component
{

    render()
    {
        return (
            
<Home crumbs={"Home|/,Sitemap|#"}>

<Hero hero_image={"assets/img/pexels-photo-126623.jpeg"}>
        <h2>
          Site Map
          <span className="usa-hero-callout-alt">
            {this.props.subtitle}
          </span>
        </h2>
      </Hero>

      <Header thin={false} crumbs="Home|/,Site Map|/sitemap"/>

<section id="sitemap" className="usa-section">
    <div className="usa-grid" style={{minHeight:"calc( 100vh - 400px )"}}>
    <ReactMarkdown source={`
## Site Map
*  [Home](/)
*  [Search](/all/?search=)
*  [Sitemap](/sitemap)
*  [Resources for Service Members](/service_members)
    *   [Tuition Assistance](/service_members/tuition_assistance)
    *   [Apprenticeship](/service_members/apprenticeships)
    *   [Financial Readiness](/service_members/financial_readiness)
    *   [Credential Support](/service_members/credentials)
    *   [Transition Assistance](/service_members/transition_assistance)
*  [Resources for Veterans](/veterans)
    *   [Funding Assistance](/veterans/funding_assistance)
    *   [Financial Readiness](/veterans/financial_readiness)
*  [Resources for Military Spouses](/spouses)
    *   [Funding Assistance](/spouses/funding_assistance)
    *   [Financial Readiness](/spouses/financial_readiness)
    *   [Job Resources](/spouses/job_resources)
*  [Resources for Partners](/partners)
*  [Resources for Supporting Resources](/supporting_resources)
    
`} renderers={{
    link:MarkLink
}}/>
    </div>
</section>

</Home>
        )
    }
}

