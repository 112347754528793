import React from 'react';

export default class GraphicList extends React.Component
{
    render()
    {
        return (
            <div className="usa-graphic_list-row">
                {this.props.children}
            </div>
        )
    }
}

