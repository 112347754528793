import React from 'react';
import settings from "../settings"
export default class MediaBlock extends React.Component {
    render() {
        let style = {};
        if (this.props.fit) {
            style = {objectFit:"contain;",objectPosition: "center;"};
        }
        if (this.props.square)
            return (
                <div className="usa-width-one-half usa-media_block ">
                    <div className="square">

                        <img src={settings.root + this.props.src} alt={this.props.alt} style={style}></img>
                        <div className="usa-media_block-body">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            )
        else return (
            <div className="usa-width-one-half usa-media_block ">
                <img className="usa-media_block-img circle" src={settings.root + this.props.src} alt={this.props.alt}></img>
                <div className="usa-media_block-body">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

