import React from 'react';
import BuildVersion from "../components/buildVersion";
import Link from "./link";
import settings from "../settings"
export default class Footer extends React.Component
{
    render()
    {
        return ( <>
            <footer className="usa-footer usa-footer-medium" role="contentinfo">

                <div className="usa-footer-primary-section">
                    <div className="usa-grid">
                        <nav className="usa-footer-nav">
                            <ul className="usa-unstyled-list">
                                <li className="usa-width-one-sixth usa-footer-primary-content">
                                    <a className="usa-footer-primary-link" href="mailto:support@usalearning.net">Feedback?</a>
                                </li>
                                <li className="usa-width-one-sixth usa-footer-primary-content">
                                <Link to="/sitemap"  className="usa-footer-primary-link">Site Map</Link>
                                </li>
                                <li className="usa-width-one-sixth usa-footer-primary-content">
                                <Link to="/regulatoryInfo"  className="usa-footer-primary-link" >Regulatory Information</Link>
                                
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="usa-footer-secondary_section">
                    <div className="usa-grid">
                        <div className="usa-footer-logo usa-width-one-half">
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="DoD Seal"  src={settings.root + "assets/img/DODc.png"}></img>
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Army Seal" src={settings.root + "assets/img/USAc.gif"}></img>
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Marines Seal" src={settings.root + "assets/img/Marines.png"}></img>
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Navy Seal" src={settings.root + "assets/img/USNc.gif"}></img>
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Air Force Seal" src={settings.root + "assets/img/Air_Force.png"}></img>
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Air Force Seal" src={settings.root + "assets/img/united-states-space-force.svg"}></img>                            
                            <img style={{width:"90px",    verticalAlign: "middle"}} alt="Marines Seal" src={settings.root + "assets/img/USCGSeal.png"}></img>
                            
                            <h3 className="usa-footer-logo-heading">Force Education and Training (FE&T)</h3>
                            <div style={{fontSize:" 1.2rem", fontWeight:" 400"}}> 
                                <small>
                                    For Government Use Only. This site is in beta and will update frequently.
                                </small>
                            </div>
                            <div style={{fontSize:" 1.2rem", fontWeight:" 400"}}> 
                                <small>
                                    Links to external sites are denoted with a <img style={{height:"1em"}} src={settings.root +"/assets/img/external-link.png"} alt=""></img> icon.
                                </small>
                            </div>
                            <div style={{fontSize:" 1.2rem", fontWeight:" 400"}}> 
                                <small>
                                    <BuildVersion />
                                </small>
                            </div>
                        </div>
                        <div className="usa-footer-contact-links usa-width-one-half">
                            {/*$0*/}
                            <h3 className="usa-footer-contact-heading">Questions or Comments?</h3>
                            <address>
                                <div className="usa-footer-primary-content usa-footer-contact_info">
                                    <p>
                                        <a target="_blank" rel="noopener noreferrer" href="tel:1-833-200-0035">833-200-0035 (Monday-Friday, 8:30 AM-6:00 PM EST)</a> 
                                    </p>
                                
                                </div>
                                <div className="usa-footer-primary-content usa-footer-contact_info">
                                    <p><a target="_blank" rel="noopener noreferrer" href="mailto:support@usalearning.net">support@usalearning.net</a></p>
                                </div>
                            </address>
                        </div>
                    </div>
                </div>
                
            </footer>
       </> )
    }
}

