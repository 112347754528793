import React, { Component } from 'react';
import polyfills from "./polyfills.js";
import './App.css';
import Page from './pages/index.jsx';
import { Route, BrowserRouter as Router } from 'react-router-dom'

import All from './pages/all';
import ScrollToTop from './components/scrollToTop';

import Regulatory from './pages/regulatoryInfo'
import Sitemap from './pages/sitemap'
import { withRouter } from 'react-router'

import Header from "./components/header";
import settings from "./settings";
import Home from "./components/home";

import Hero from "./components/hero";
import PageTitle from "./components/pageTitle";
import CategoryRenderer from "./components/categoryRenderer";
import ResourceRenderer from "./components/resourceRenderer";
import HeaderRenderer from "./components/headerRenderer";

import DB from "./database";

const audDisplay = {
  service_members: "Service Members",
  veterans: "Veterans",
  spouses: "Spouses",
  partners: "Partners",
  supporting_resources: "Supporting Resources"
}

const catDisplay = {
  TuitionAssistance: "Tuition Assistance",
  FinancialReadiness: "Financial Readiness",
  Spouse: "Job Resources",
  TransitionAssistance: "Transition Assistance",
  Credentials: "Credentials",
  FundingAssistance:"Funding Assistance",
  Apprenticeships:"Apprenticeships",
  JobResources:"Job Resources",
  SupportingResources: "Supporting Resources"
}

const catPath = {
  TuitionAssistance: "tuition_assistance",
  FinancialReadiness: "financial_readiness",
  Spouse: "job_resources",
  TransitionAssistance: "transition_assistance",
  Credentials: "credentials",
  FundingAssistance:"funding_assistance",
  Apprenticeships:"apprenticeships",
  JobResources:"job_resources",
  SupportingResources: "supporting_resources"
}


function crumbsFromAudienceCategory(aud, cat) {
  let cmbs = ["Home|/"];
  cmbs.push(audDisplay[aud] + "|" + "/" + aud);
  if (cat && catDisplay[cat])
    cmbs.push(catDisplay[cat] + "|" + "/" + aud +"/"+catPath[cat]);
  return cmbs.join(",");

}

class AudiencePage extends Component {
  render() { 
    
    let hero_image = DB.getAudience(this.props.audience).hero_image;
    return <Home >
      <Hero hero_image={hero_image}>
        <h2>
          {this.props.title || "For " + audDisplay[this.props.audience]}
          <span className="usa-hero-callout-alt">
            {this.props.subtitle}
          </span>
        </h2>
      </Hero>

      <Header thin={false} crumbs={crumbsFromAudienceCategory(this.props.audience)} />

      <PageTitle >
        {this.props.heading || this.props.audience === "supporting_resources" ? audDisplay[this.props.audience] : "Resources for " + audDisplay[this.props.audience]}
      </PageTitle>

      <CategoryRenderer audience={this.props.audience} />
    </Home>
  }
}

class CategoryPage extends Component {
  
  render() {
    // added bypass boolean prop to quickly fix newly added category breaking the hero image
    let hero_image = DB.getCategory(this.props.byPass ? 'supporting_resources' : this.props.audience,this.props.category).hero_image;
    return <Home>
      <Hero hero_image={hero_image}>
        <h2>
          <span className="usa-hero-callout-alt">{audDisplay[this.props.audience]}</span>
          {this.props.subtitle || catDisplay[this.props.category] +" Resources"}
        </h2>
      </Hero>

      <Header thin={false}  crumbs={crumbsFromAudienceCategory(this.props.audience,this.props.category)} />

      <HeaderRenderer audience={this.props.audience} category={this.props.category}></HeaderRenderer>
      <ResourceRenderer audience={this.props.audience} category={this.props.category}></ResourceRenderer>
    </Home>
  }
}
let root = settings.root;
    if(root === "/")
      root = "";

class Routes extends Component {
  constructor(props) {
    super(props);

    this.props.history.listen((location, action) => {
      /*global ga*/
      ga('set', 'page', location.pathname);
      ga('send', 'pageview');
    })
  }

  render() {
    

    return (<>

      <Route exact path={root + "/"} component={Page} />
      <Route exact path={root + "/all"} component={All} />
      <Route exact path={root + "/service_members"} render={(props) => <AudiencePage audience="service_members" subtitle="Programs for personal and professional development - Active duty and beyond." />} />
      <Route exact path={root + "/service_members/tuition_assistance"} render={(props) => <CategoryPage audience="service_members" category="TuitionAssistance" />} />
      <Route exact path={root + "/service_members/credentials"} render={(props) => <CategoryPage audience="service_members" category="Credentials" subititle="Credential Support Resources"/>} />
      <Route exact path={root + "/service_members/apprenticeships"} render={(props) => <CategoryPage audience="service_members" category="Apprenticeships" />} />
      <Route exact path={root + "/service_members/financial_readiness"} render={(props) => <CategoryPage audience="service_members" category="FinancialReadiness" />} />
      <Route exact path={root + "/service_members/transition_assistance"} render={(props) => <CategoryPage audience="service_members" category="TransitionAssistance" byPass={true}/>} />
      <Route exact path={root + "/veterans"} render={(props) => <AudiencePage audience="veterans" subtitle="Learn about opportunities available to Veterans" />} />
      <Route exact path={root + "/veterans/funding_assistance"} render={(props) => <CategoryPage audience="veterans" category="FundingAssistance" />} />
      <Route exact path={root + "/veterans/financial_readiness"} render={(props) => <CategoryPage audience="veterans" category="FinancialReadiness" />} />
      <Route exact path={root + "/spouses"} render={(props) => <AudiencePage audience="spouses" subtitle="Spouse-specific resources for education and careers" />} />
      <Route exact path={root + "/spouses/funding_assistance"} render={(props) => <CategoryPage audience="spouses" category="FundingAssistance" subtitle="Funding Assistance Resources" />} />
      <Route exact path={root + "/spouses/job_resources"} render={(props) => <CategoryPage audience="spouses" category="JobResources" subtitle="Job Resources for Spouses" />} />
      <Route exact path={root + "/spouses/financial_readiness"} render={(props) => <CategoryPage audience="spouses" category="FinancialReadiness" />} />
      <Route exact path={root + "/spouses/transition_assistance"} render={(props) => <CategoryPage audience="spouses" category="TransitionAssistance" />} />
      <Route exact path={root + "/partners"} render={(props) => <CategoryPage audience="partners" category="*" subtitle="Help Service members, Veterans, and Spouses" />} />
      <Route exact path={root + "/supporting_resources"} render={(props) => <AudiencePage audience="supporting_resources" subtitle="Programs for personal and professional development - Active duty and beyond." />} />
      
      <Route exact path={root + "/regulatoryInfo"} component={Regulatory} />
      <Route exact path={root + "/sitemap"} component={Sitemap} />
     
    </>
    );
  }
}
Routes = withRouter(Routes);
class App extends Component {
  render() {
    return (
      <Router >
        <ScrollToTop>
          <div>
            <Routes />
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
