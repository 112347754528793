import React from 'react';
import database from "../database";
import Section from "../components/section";
import MarkLink from '../components/markLink';
import MediaBlock from "../components/mediaBlock";
import ReadMore from "../components/readMore";
import Link from "./link";
const ReactMarkdown = require('react-markdown');

function readMore(aud) {
    if (aud.readMore) {
        return <div><ReadMore>
            <ReactMarkdown source={aud.readMore} renderers={{link:MarkLink}}/>
            </ReadMore></div>
    }
}
function renderLink(cat)
{
    
    return <Link className="usa-button" to={cat.link}>
            {cat.linkText}
        </Link>
    
}
function renderCat(cat) {
    return <MediaBlock src={cat.imgSrc} square={true}>
        <h3 id="assessment">{cat.title}</h3>

        <div className="usa-media_block-body-inner">
           
            <ReactMarkdown source={cat.text} renderers={{link:MarkLink}}/>
            
        </div>
        {readMore(cat)}
       
            {renderLink(cat)}
        
    </MediaBlock>
}

export default class CategoryRenderer extends React.Component {

    render() {
        let cats = database.getCategories(this.props.audience);
        let pageDescription = cats.filter(element => element.pageDescription);
        return <Section noTop={true}>
            {pageDescription.length > 0 &&
                <p className="usa-font">
                    {pageDescription[0].pageDescription}
                </p>
            }
            <div className="marginbottom ">
                {cats.map(renderCat)}
            </div>
        </Section>

    }
}

