import React from 'react';

export default class Section extends React.Component {
    render() {
        let className = "usa-section ";
        if(this.props.dark)
            className += " usa-section-dark";
        let style = {};
        if(this.props.noBottom)
            style.paddingBottom = "0";
        if(this.props.noTop)
            style.paddingTop = "0";
        return (
            <section className={className} style={style}>
                <div className="usa-grid">

                    {this.props.heading ? <h2>{this.props.heading}</h2> : null}

                    {this.props.children}
                </div>
            </section>
        )
    }
}

