import React from 'react';

export default class PageTitle extends React.Component
{
    render()
    {
        return (           
            <div className="usa-grid">
                <div className="usa-logo" id="extended-logo" >
                    <em className="usa-logo-text">
                        {/* <a href="/" title="" aria-label=""> */}
                        {this.props.children}
                        {/* </a> */}
                    </em>
                </div>
            </div>
        )
    }
}

