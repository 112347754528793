import OfficialBanner from "../components/officialBanner";
import Footer from "../components/footer";
import BodyScripts from "../components/bodyScripts";
import React from 'react';

export default class Home extends React.Component
{
    render()
    {
        return (<>
            <div className="page-landing-page layout-demo " cz-shortcut-listen="true">
                <a className="usa-skipnav" href="#main-content">Skip to main content</a>
                <OfficialBanner />
                <main id="main-content">
                    {this.props.children}
                </main>
                <Footer />
                <BodyScripts />
            </div>
        </>)
    }
}

