
import SecondaryNav from "../components/secondaryNav";
import Crumbs from "../components/crumbs";
import React from 'react';
import Link from "../components/link";
import MenuItem from "../components/menuitem";
import settings from "../settings";
export default class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = { navVisible: false };
        this.showNav = this.showNav.bind(this);
        this.hideNav = this.hideNav.bind(this);
    }
    showNav() {
        this.setState(s => {
            s.navVisible = true;
            return s;
        })
    }
    hideNav() {
        this.setState(s => {
            s.navVisible = false;
            return s;
        })
    }
    render() {
        return (
            <>
                <header className={"usa-header usa-header-extended" + (this.props.thin ? "thin" : "")} role="banner">

                    <div className={"usa-navbar"}>
                        <button className="usa-menu-btn" onClick={this.showNav}>Menu</button>
                    </div>
                    <nav role="navigation" className={"usa-nav" + (this.state.navVisible ? " is-visible" : "")}>
                        <div className="usa-nav-inner">
                            <button className="usa-nav-close">
                                <img src={settings.root + "assets/img/close.svg"} alt="close" onClick={this.hideNav}></img>
                            </button>

                            <Crumbs crumbs={this.props.crumbs}>
                            </Crumbs>
                            <ul className="usa-nav-primary usa-accordion">
                                <MenuItem text="Main Menu">
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/all/?search=">Search</Link>
                                    </li>
                                    <li>
                                        <Link to="/sitemap">Site Map</Link>
                                    </li>
                                    <li>
                                        <Link to="/regulatoryInfo">About</Link>
                                    </li>
                                    
                                </MenuItem>
                                <MenuItem text="Resources">
                                    <li>
                                        <Link to="/service_members">For Service Members</Link>
                                    </li>
                                    <li>
                                        <Link to="/veterans">For Veterans</Link>
                                    </li>
                                    <li>
                                        <Link to="/spouses">For DoD Spouses</Link>
                                    </li>
                                    <li>
                                        <Link to="/partners">For Partners</Link>
                                    </li>
                                    <li>
                                        <Link to="/supporting_resources">Supporting Resources</Link>
                                    </li>
                                </MenuItem>
                                



                            </ul>

                            <div className="usa-nav-secondary">
                                <SecondaryNav />
                            </div>
                        </div>
                    </nav>
                </header>
                
                <div className="usa-overlay"></div>
            </>)
    }
}

