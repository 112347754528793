import React from 'react';

export default class BuildVersion extends React.Component
{
    render()
    {
        let buildVersion = "React v1.0";
        return (
            <span>Build: { buildVersion }</span>
        )
    }
}

