
import React from 'react';
export default class ReadMore extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded:false
        }
        this.expand = this.expand.bind(this);
    }
    expand()
    {
        // console.log("got here");
        this.setState( (e) =>{
            return {
                expanded: !e.expanded
            }
        })
    }
    render() {
        let id = require("uuid").v4();
        let expanded = this.state.expanded;
        let style = {
            display:"inline"
        }
        if(!expanded)
            style.display = "none";
        return (<>


            
                    <a onClick={this.expand} className="usa-accordion-button readmore" aria-expanded={expanded} aria-controls={id + '-content'}>
                        Read More ...
                    </a>
                    <div id={id + '-content'} className="usa-accordion-content readmore" aria-hidden={!expanded} style={style}>
                        
                            {this.props.children}
                        
                    </div>
                
       </> )
    }
}

