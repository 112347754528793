import React from 'react';
import Link from "./link";

export default class AudienceTypes extends React.Component {
    constructor(props) {
        super(props)     
        this.state = {
            expanded:false
        }
        this.expand = this.expand.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    setWrapperRef(node) {
        this.wrapperRef = node;
    }
    handleClickOutside(event) {
       
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState( (e)=>{
                return {
                    expanded: false
                }
            })
        }
        return false;
    }
    expand()
    {
        this.setState( (e)=>{
            return {
                expanded: !e.expanded
            }
        })
    }
    render()
    {
        let expanded = this.state.expanded;
        let style = {
            display:"block"
        }
        let menustyle = {
            
        }
        if(!expanded)
            style.display = "none";
        if(expanded)
        {
            menustyle.backgroundColor = "#112e51";
            menustyle.color = "white";
        }
        return (
            <li ref={this.setWrapperRef} onClick={this.expand}><button  className="usa-accordion-button usa-nav-link" aria-expanded="false" aria-controls="extended-nav-section-two" style={menustyle}>
                <span>{this.props.text}</span>
            </button>
                <ul id="extended-nav-section-two" className="usa-nav-submenu" style={style}>
                    {this.props.children}
                </ul>
            </li>
        )
    }
}

