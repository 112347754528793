import React from 'react';
import database from "../database";
import PageTitle from "../components/pageTitle";

import ReadMore from "../components/readMore";

const ReactMarkdown = require('react-markdown');

function readMore(aud) {
    if (aud.readMore) {
        return <div><ReadMore> <ReactMarkdown source= {aud.readMore} /></ReadMore></div>
    }
}


export default class HeaderRenderer extends React.Component {

    render() {
        
        let res = database.getHeader(this.props.audience, this.props.category);
        
        return <>
        <PageTitle >
            {res.title}
        </PageTitle>

        <div className="resource-list-heading usa-grid">
            <div className="resource-list-heading-text">
            <ReactMarkdown source= {res.text} />   
            {readMore(res)}
            </div>
        </div>
    </>

    }
}

