import Home from "../components/home";
import MarkLink from '../components/markLink';
import React from 'react';
import Hero from "../components/hero";
import Header from "../components/header";
const ReactMarkdown = require('react-markdown');

export default class RegulatoryInfo extends React.Component
{

    render()
    {
        return (
            
<Home crumbs={"Home|/,Regulatory Info|#"}>

<Hero hero_image={"assets/img/2756715.jpg"}>
        <h2>
          About
          <span className="usa-hero-callout-alt">
            {this.props.subtitle}
          </span>
        </h2>
      </Hero>

      <Header thin={false} crumbs="Home|/,About|/regulatoryInfo"/>

<section id="sitemap" className="usa-section">
    <div className="usa-grid">
    <ReactMarkdown source={`
## Web Accessibility Statement

We are committed to providing access to our web pages for individuals with disabilities, both members of the public and Federal employees.

To meet this commitment, we will comply with the requirements of Section 508 of the Rehabilitation Act Amendments of 1998 (29 U.S.C. &sect; 794) and the accessibility standards issued by the Architectural and Transportation Barriers Compliance Board.

Section 508 requires that individuals with disabilities, who are members of the public seeking information or services from us, have access to and use of information and data that is comparable to that provided to the public who are not individuals with disabilities, unless an undue burden would be imposed on us. Section 508 also requires us to ensure that Federal employees with disabilities have access to and use of information and data that is comparable to the access to and use of information and data by Federal employees who are not individuals with disabilities, unless an undue burden would be imposed on us.

Comments on the accessibility of this site should be sent to support@usalearning.net

## Disclaimer for external links

The appearance of hyperlinks on this page does not constitute endorsement by the Federal Government, Department of Defense, or other federal agency of linked websites or the information, products or services contained therein. DoD does not exercise any editorial control over the information you may find at linked locations. Links to external links sites may only be provided when consistent with DoD policies on website administration. Questions or concerns about links provided on this website should be addressed to the DoD Office of Congressional and Public Affairs.


## Web Privacy Notice

### Last Updated: July 1, 2018

 

Thank you for visiting the DoD Career Ready website and reviewing our privacy policy. Our privacy policy is clear: We will collect no personal information about you when you visit our website unless you choose to provide the information to us.

Here is how we handle information about your visit to our website:

Information Collected and Stored Automatically

If you visit our site to read or download information, we collect and store the following information about your visit:

The name of the Internet domain (for example, "xcompany.com" if you use a private Internet access account, or "yourschool.edu" if you are connecting from a university's domain) and the IP address (a number automatically assigned to your computer when you are using the Internet) from which you access our site;

The type of browser and operating system used to access our site;

The date and time you access our site;

The Internet address of the website from which you linked directly to our site; and

The pages you visit and the information you request.

This information is primarily collected for statistical analysis and technical improvements to the site. This government computer system uses software programs to create summary statistics, which may be used for such purposes as assessing what information is of most and least interest, determining technical design specifications, and identifying system performance or problem areas. When required by law or security related necessity, we may take additional steps to identify you based on collected information and we may share this information with other government agencies.

## If You Send Us Personal Information

You do not have to provide any personal information to visit this website. If you choose to provide us with personal information, such as by sending a message to an email address on this website or by filling out a form and submitting it through our website, we will use that information to respond to your message or to fulfill the stated purpose of the communication. If you submit your resume to us electronically we will use your personal information to consider your application for employment. DoD does not collect, use, or sell information for commercial marketing. We may share information you give us with contractors acting on our behalf or with another government agency if your inquiry relates to that agency. In other limited circumstances, such as responses to requests from Congress and private individuals, we may be required by law to disclose information you submit. If you provide comments in response to a request for public comments we may make those comments as well as your identity available to the public in a publication or by posting them on our website. Where possible we may give you more specific guidance at the point of collection regarding how your personal information may be used or disclosed.

Electronically submitted information is maintained and destroyed according to the principles of the Federal Records Act and the regulations and records schedules of the National Archives and Records Administration and in some cases may be covered by the Privacy Act and subject to the Freedom of Information Act. View a discussion of your rights under these laws (external link).

Remember, email is not necessarily secure against interception. If your communication is sensitive or includes personal information you may prefer to send it by postal mail instead.

## Cookies

"Cookies" are small bits of text which are either used for the duration of a session ("session cookies"), or saved on a user's hard drive in order to identify that user, or information about that user, the next time the user logs on to a website ("persistent cookies"). DoD and its program sites may use Tier 1, Tier 2, and Tier 3 cookies as defined in the Office of Management and Budget memorandum guidance. (external link) (PDF file) [102 KB]

 

DoD utilizes web measurement technology in order to improve our website and provide a better user experience for our customers. The Office of Management and Budget classifies this as a Tier 2 usage, since we are utilizing a multi-session web measurement technology that does not collect any personally identifiable information (PII). This technology anonymously tracks how visitors interact with opm.gov, including where they came from, what they did on the site, and whether they completed any pre-determined tasks while on the site.

 

This technology is provided by Google Analytics and the information collected is used to optimize our website; helping us determine top tasks, improve our user interface and diversify our content offerings to meet the needs of our customers. No personally identifiable information is collected, so the anonymity of the end user is protected. The measurement data that is collected is only retained for as long as is needed for proper analysis and optimization of the website and is accessible only to employees whose position necessitates it.

 

Since disabling this web measurement technology requires modifying individual browser settings it is enabled by default. If you wish to opt-out of this technology, you can use these step-by-step instructions (external link) for changing your settings.

 

Google also provides a browser plug-in that will allow you to opt-out (external link) of all Google Analytics measurements. Please note that opting-out in no way affects your access to content within opm.gov or how you see the site.

 

DoD may make very limited use of some Tier 3 persistent cookies to allow cross session and website authentication. DoD will make notice of the use of such technologies on the application or websites employing them and inform the user to opt-in to allow the Tier 3 cookie to be set before engaging DoD. DoD does not make use of any persistent cookies, which can be accessed by third party entities.

 

## Our Social Network Policy

DoD participates in open, un-moderated forums offered by commercial social networks sites in order to increase government transparency, promote public participation, and encourage collaboration with the Agency. DoD currently maintains official accounts on Facebook (external link), YouTube (external link), Flickr (external link), and Twitter (external link). DoD also engages in the use of social bookmarking applications such as AddThis (external link). DoD does not control, moderate, or endorse the comments or opinions provided by visitors to these sites. In addition, the Agency does not collect, maintain or disseminate information posted there.

 

## Links to Other Sites

Our website has links to other federal agencies. We also link to other organizations’ websites when we have a good business reason to do so. This does not constitute an endorsement of their policies or products. Once you link to another site, you are subject to the privacy policy of the new site.

 

## Site Security

For site security purposes and to ensure this service remains available to all users, this Government computer system employs software programs to monitor network traffic to identify unauthorized attempts to upload or change information, or otherwise cause security related problems. Anyone using this system expressly consents to such monitoring and is advised if such monitoring reveals evidence of possible abuse or criminal activity, such evidence may be provided to appropriate law enforcement officials. Unauthorized attempts to upload or change information on this server are strictly prohibited and may be punishable by law, including the Computer Fraud and Abuse Act of 1986 and the National Information Infrastructure Protection Act of 1996.

 
*  [Plain Language Act](http://www.esd.whs.mil/DD/plainlanguage/)
*  [FOIA](https://www.dss.mil/foia/index.html)
*  [No FEAR Act](https://www.dss.mil/eeo/no_fear.html)
`} renderers={{
    link:MarkLink
}}/>
    </div>
</section>

</Home>
        )
    }
}

