import React from 'react';
import { withRouter } from 'react-router'
import settings from "../settings";
class SecondaryNav extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false
        }
        this.expand = this.expand.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
    }
    expand() {
        console.log("got here");
        this.setState((e) => {
            return {
                expanded: !e.expanded
            }
        })
    }
    componentDidMount() {
        /* global $ */
        let root = settings.root;
        if(root === "/")
        root = "";
        const router = this.props.history;
        let self = this;
        $("#header-search").click(function (e) {
            e.preventDefault();
            router.push(root+"/all?search=" + $("#header-search-form").val());
            self.expand();
            return false;
        })
    }
    render() {
        // let id = require("uuid").v4();
        let expanded = this.state.expanded;
        let style = {
            display: "block"
        }
        let style2 = {
            display: "none"
        }
        if (!expanded)
            style.display = "none";
        if (!expanded)
            style2.display = "inline-block";

        return (
            <ul className="usa-unstyled-list usa-nav-secondary-links">
                <li className="js-search-button-container">
                    <form className="usa-search usa-search-small  js-search-form" style={style}>
                        <div role="search">
                            <label className="usa-sr-only" htmlFor="header-search-form">Search small</label>
                            <input type="search" name="search" id="header-search-form"></input>
                            <button type="submit" id="header-search">
                                <span className="usa-sr-only">Search</span>
                            </button>
                        </div>
                    </form>
                    <button onClick={this.expand}  style={style2} className="usa-header-search-button js-search-button">Search</button>
                </li>
                {/*<!--<li>
                            <a href="">
                                Secondary priority link
                            </a>
                        </li>-->*/}
            </ul>

        )
    }
}
export default withRouter(SecondaryNav);
