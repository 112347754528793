import React from 'react';
import database from "../database";
import Section from "../components/section";

import MarkLink from '../components/markLink';
import Resource from "../components/resource";
import ReadMore from "../components/readMore";
const ReactMarkdown = require('react-markdown');

function readMore(aud) {
    if (aud.readMore) {
        return <div><ReadMore>{aud.readMore}</ReadMore></div>
    }
}
function renderRes(res) {
    return <Resource title={res.title}
        href={res.link}
        src={res.imgSrc}
        link={res.linkText}
        visible={true}>

        <ReactMarkdown source={res.text} renderers={{link:MarkLink}}/>
        

        {readMore(res)}
    </Resource>
}

export default class CategoryRenderer extends React.Component {

    render() {
       
        let res = database.getResources(this.props.audience, this.props.category);
       
        return <Section noTop={true}>
            <div className="marginbottom ">
                {res.map(renderRes)}
            </div>
        </Section>

    }
}

