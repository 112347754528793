import React from 'react';
import Link from "./link";
export default class MarkLink extends React.Component
{

    render()
    {
        let text = this.props.children[0].props.value;
        return <Link to={this.props.href}>{text}</Link>
    }
}