import React from 'react';

export default class BodyScripts extends React.Component {
    render() {
        return (<>

        </>)
    }
}

