import SiteTitle from "../components/siteTitle";
import React from 'react';
import settings from "../settings"
let heroImages = [ 
    "assets/img/getty_568777661_101264.jpg",
    "assets/img/3114710.jpg",
    "assets/img/2756715.jpg",
    "assets/img/3599642.jpg",
    "assets/img/3114713.jpg",
    "assets/img/marine_tap.jpg",
    "assets/img/pexels-photo-120232.jpeg",
    "assets/img/sailors.png",
    "assets/img/marine_finred.jpg",
  ]

function walkChildren(obj,str)
{ 
    if(!obj || !obj.children || !obj.children.props || !obj.children.props.children) return str;
    for(let i in obj.children.props.children)
    {
        if(typeof obj.children.props.children[i] == 'string')
        str += obj.children.props.children[i];
        else
        {
            if(obj.children.props.children[i])
            str += walkChildren(obj.children.props.children[i],str);
        }
    }
    return str;
}

export default class Hero extends React.Component {
    render() {
        let childContent = (walkChildren(this.props,""));
        let childImage = childContent.split("").reduce( (p,v) => v.charCodeAt(0) + p, 0);
       
        let childSelector = (childImage - (heroImages.length) * Math.floor(childImage / (heroImages.length)));
        let heroimage = heroImages[childSelector];
        //"url({{root}}{{default hero_image (randomHeroImage)}})"
        if (this.props.hero_image)
            heroimage = this.props.hero_image;
        
        return (
            <section className="usa-hero usa-hero-image" style={{ backgroundImage: "url("+settings.root+heroimage+")" }}>
                <div className={"usa-grid " + (this.props.thin ? "thin" : "")}>
                    <div className="usa-hero-callout usa-section-dark" >

                        {!this.props.noSiteTitle ? <SiteTitle /> : null}

                        {this.props.children}
                    </div>
                </div>
            </section>
        )
    }
}

