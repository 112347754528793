
import Header from "../../components/header";

import Home from "../../components/home";
import { withRouter } from 'react-router-dom'
import Hero from "../../components/hero";
import PageTitle from "../../components/pageTitle";
import Section from "../../components/section";
import React from 'react';
import $ from 'jquery';
import ResourceRenderer from "../../components/resourceRenderer";
import settings from "../../settings";
function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
let router = null;
class Index extends React.Component {
    constructor(props) {
        super(props)
        this.redraw = this.redraw.bind(this);
    }
    componentDidUpdate()
    {
        this.redraw();
    }
    componentWillReceiveProps()
    {
        this.redraw();
    }
    componentDidMount()
    {
        this.link();
        this.redraw();
    }
    redraw()
    {
        $("#no-results").addClass('hidden');
        let searchval = decodeURIComponent(getUrlVars()['search']).split(" ").map(function (e) {
            return "(" + e + ")"
        }).join('|');

        if (searchval)
            $("#search-field").val(decodeURIComponent(getUrlVars()['search']));
        if (!getUrlVars()['search'])
            searchval = ".*";
        let search;
        try{

            search =  RegExp(searchval, "ig");
        }catch(e)
        {
            search =  RegExp("no results should match", "ig");
        }

        var galleryItems = document.querySelectorAll('main .usa-width-one-half');

        [].forEach.call(galleryItems, function(item, i) {
        item.classList.remove('clearNone', i % 2);
        item.classList.remove('clearBoth', !(i % 2))
        item.classList.remove('hidden', !(i % 2))
        });

        let found = false;
        $("main .usa-width-one-half").each(function (i, e) {
            let html = $(e).text();
            if (html.match(search)) {
                $(e).removeClass('hidden');
                found = true;
            }
            else {
                $(e).addClass('hidden');
            }
        })
        if (!found) {
            $("#no-results").removeClass('hidden');
        }

        galleryItems = document.querySelectorAll('main .usa-width-one-half:not(.hidden)');

        [].forEach.call(galleryItems, function(item, i) {
        item.classList.toggle('clearNone', i % 2);
        item.classList.toggle('clearBoth', !(i % 2))
        });
    }
    link() {
    
        let root = settings.root;
        if(root === "/")
        root = "";
        $("#body-submit").click(function (e) {
            e.preventDefault();
            //window.location = "/all?search=" + $("#search-field").val();
            router.push(root+"/all?search=" + $("#search-field").val())
            return true;
        })

       
    }
    render() {
        router = this.props.history;
     //   setTimeout(this.redraw,200);
        return (<>
            <style dangerouslySetInnerHTML={{
                __html: `
                .hidden {
                    display: none !important;
                }
            `}}>
            </style>
            <Home >
                <Hero hero_image={"assets/img/3599642.jpg"}>
                    <h2>
                        All Resources
                    <span className="usa-hero-callout-alt">
                            Search all available resources
                    </span>
                    </h2>
                </Hero>
                <Header crumbs={"Home|/,Search Results|/all?search="}  thin={false} />

                <PageTitle >
                    Search All Resources
                </PageTitle>
                <Section noTop={true}>

                    <div className="marginbottom ">
                        <div className="text">
                            <h6>Search Resources</h6>
                            <div role="search">
                                <form className="usa-search">
                                    <label className="usa-sr-only" htmlFor="search-field">Search</label>
                                    <input id="search-field" type="search" name="search"></input>
                                    <button type="submit" id="body-submit">
                                        <span className="usa-search-submit-text">Search</span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <ResourceRenderer audience="*" category="*"></ResourceRenderer>
                </Section>


                <section className="usa-grid usa-section hidden" id="no-results">
                    <h2>No results found</h2>
                </section>

            </Home>

            <script dangerouslySetInnerHTML={{
                __html: `
    // Read a page's GET URL variables and return them as an associative array.
    
    `}}>
            </script>
        </>)
    }
}

export default withRouter(Index)

