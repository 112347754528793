import Header from "../components/header";
import SiteTitle from "../components/siteTitle";
import AudienceRenderer from "../components/audienceRenderer";
import Home from "../components/home";

import Hero from "../components/hero";
import ReadMore from "../components/readMore";
import Section from "../components/section";

import React from 'react';

export default class Index extends React.Component
{
    render()
    {
        return (
            <Home >
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <Hero hero_image={"assets/img/navy-quartermaster.jpg"} noSiteTitle={true}>
                            <h2>
                                <span className="usa-hero-callout-alt">United Services Military Apprenticeship Program – USMAP</span>
                                <span>Enhance your skills while on active duty and prepare for the future!</span>
                            </h2>
                            <a className="usa-button cta" href="https://usmap.netc.navy.mil/usmapss/static/index.htm" target="_blank" rel="noopener noreferrer">See USMAP<img src="/assets/img/external-link-alt.png" alt="" ></img></a>
                            </Hero>
                        </div>
                        <div className="carousel-item">
                            <Hero hero_image={"assets/img/skillbridgebackground.png"} noSiteTitle={true}>
                            <h2>
                                <span className="usa-hero-callout-alt">DoD SkillBridge</span>
                                Connecting transitioning Service members to career job training opportunities.
                            </h2>
                            <a className="usa-button cta" target="_blank" rel="noopener noreferrer" href="https://dodskillbridge.usalearning.gov/">Visit DoD SkillBridge<img src="/assets/img/external-link-alt.png" alt="" ></img></a>
                            </Hero>
                        </div>
                        
                        <div className="carousel-item ">
                            <Hero hero_image={"assets/img/mil-2-mariner.png"} noSiteTitle={true}>
                            <h2>
                                <span className="usa-hero-callout-alt">Mil-2-Mariner</span>
                                Interested in pursuing a civilian mariner career upon leaving active duty service?
                            </h2>
                            <a className="usa-button cta" href="https://milgears.osd.mil/Mariner"  target="_blank" rel="noopener noreferrer">Get Started<img src="/assets/img/external-link-alt.png" alt="" ></img></a>
                            </Hero>
                        </div>
                       
                        
                    </div>
                    <a className="carousel-control-prev" style={{width: `4%`}} href="#carouselExampleIndicators" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" style={{width: `4%`}} href="#carouselExampleIndicators" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                <Header crumbs={"Home|/"}/>
                <div className="usa-grid">
                    <SiteTitle />
                </div>
                <div className="">



                <Section heading={"Welcome to the Career Ready Portal"} dark={false} noTop={true} noBottom={true}>
                    <p className="usa-font">The Department of Defense (DoD), Department of Labor (DoL), and Veterans Administration (VA)
                        work collaboratively to provide resources designed to help Service members, Veterans, and their spouses plan
                        and achieve their career, education, and financial goals. Career Ready Portal provides an organized set of
                        information and links to these valuable resources with more features and resources planned over time. </p>

                    <ReadMore >
                    <p className="usa-font">User <a target="_blank" rel="noopener noreferrer" href="mailto:support@usalearning.net">feedback</a> will guide continual
                        improvements to features and resources provided through the Career Ready Portal. Please explore, learn, improve
                        and provide us your <a target="_blank" rel="noopener noreferrer" href="mailto:support@usalearning.net">feedback</a> so we can as well. We
                        are listening.</p>
                    </ReadMore>

                </Section>

                <AudienceRenderer/>

            </div>
            <Section heading={""} dark={true}>
                <Section noTop={true} heading={"America's employers value and seek out veterans"} dark={true}>

                <p>America’s employers seek out veterans as preferred job applicants. Employers value the knowledge, skills, and
                    abilities that Service members bring to the workforce. Take advantage of tuition assistance and support for
                    attaining professional certifications, licensures, and apprenticeships that improve your career prospects by
                    translating your valuable military experience into the language of civilian employers on your resume. </p>

                </Section>
            </Section>


            <Section heading={""} dark={false}>
                <Section noTop={true} heading={"Featured Resource: DoD Skillbridge"} dark={false}>
                <p className="usa-font">SkillBridge is an excellent opportunity as you plan for your life after the military. SkillBridge matches civilian opportunities to your job training and work experience at the end of your military duty. In addition to opportunities such as Tuition Assistance and the GI Bill program, you can enhance your marketability and post-separation career prospects by participating in a SkillBridge opportunity.

Any rank, enlisted or officer, may apply for SkillBridge. SkillBridge permits you to use up to the last 180 days of Service to work and learn with an industry partner. During SkillBridge participation you continue to receive military compensation and you are covered by your military benefits. Release for SkillBridge is always mission-dependent and your unit Commander must authorize participation prior to entering into any agreement with interested industry employment partners. </p>
                <a className="usa-button cta" target="_blank" rel="noopener noreferrer" href="https://dodskillbridge.usalearning.gov/">Visit DoD SkillBridge  <img src="/assets/img/external-link-alt.png" alt="" ></img></a>

                </Section>
            </Section>
        </Home>
        )
    }
}

