let i =0;
export default function()
{
    i ++;
      let list = [
        "assets/img/4731925.jpg",
        "assets/img/7034326-3x2-940x627.jpg",
        "assets/img/4628859.jpg",
        "assets/img/1000w_q75.jpg",
        "assets/img/1000w_q95.jpg",
        "assets/img/1000w_q95 (1).jpg",
        "assets/img/1000x667_q95.jpg",
        "assets/img/811403.jpg",
        "assets/img/2702214.jpg",
        "assets/img/3085381.jpg",
        "assets/img/3114710.jpg",
        "assets/img/1000w_q75 (1).jpg",
        "assets/img/apprenticeship-program.jpg",

        "assets/img/ServiceMembersInLibrary.jpg",
        "assets/img/troops to teachers2.jpg",
        "assets/img/troops to teachers3.jpg",
        "assets/img/Dantes2.jpg",
        "assets/img/Dantes3.jpg",
      ];
      if(i > list.length -1 )
        i = 0;
      return list[i];
}