import React from 'react';
import database from "../database";
import Section from "../components/section";
import GraphicList from "../components/graphicList";

import MediaBlock from "../components/mediaBlock";
import ReadMore from "../components/readMore";
import Link from "./link";
import settings from "../settings"
function readMore(aud) {
    if (aud.readMore) {
        return <div><ReadMore>{aud.readMore}</ReadMore></div>
    }
}
function renderAud(aud) {
    return <MediaBlock src={aud.imgSrc} alt={"Alt text"}>
        <h3>{aud.title}</h3>

        <p>{aud.text}</p>
        {readMore(aud)}
        <Link to={aud.link} className="usa-button">
            {aud.linkText}
        </Link>
    </MediaBlock>
}

export default class AudienceRenderer extends React.Component {

    render() {
        let auds = database.getAudiences();

        return <div id="audiences">
            <Section heading={""} dark={false} noTop={true}>
                <GraphicList >
                    {auds.map(renderAud)}
                </GraphicList>
            </Section>
        </div>
    }
}

