import React from 'react';
import { Link } from 'react-router-dom'
import settings from '../settings';


export default class _Link extends React.Component {
    render() {
        let root = settings.root;
        let p = Object.assign(new Map(),this.props);
        if(!p.className)
            p.className = "";
        p.className += " cta";
        if(root === "/")
         root = ""
        if (this.props.to.indexOf("http") === 0)
            return <a className={p.className} style={p.style} target="_blank" rel="noopener noreferrer" href={this.props.to}> <img src={settings.root +"assets/img/external-link-alt.png"} alt="" ></img> {this.props.children}     </a>
        return <Link  className={p.className} style={p.style} to={root+this.props.to}>
            {
                this.props.children
            }
        </Link>;
    }
}

