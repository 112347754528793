import React from 'react';
import Link from "./link";
import settings from "../settings"
export default class SiteTitle extends React.Component
{
    render()
    {
        return (
            <div className="usa-logo" id="extended-logo" >
                <em className="usa-logo-text">
                    {/* <Link to="/" title="Home" aria-label="Home"> */}
                        <img alt="DoD Seal" style={{height:"2.5em",  width:"2.5em",  verticalAlign: "middle"}} src={settings.root +"assets/img/DODc.png"}></img> DoD Career Ready
                    {/* </Link> */}
                </em>
            </div>
        )
    }
}

