import React from 'react';
import Link from "./link";

function c(crumbs) {
    if (!crumbs)
        return null;
    let html = []
    crumbs = crumbs.split(",");
    for (let i in crumbs) {
        let text = crumbs[i].split('|')[0];
        let link =  crumbs[i].split('|')[1];

        if (i === crumbs.length - 1) {
            html.push(<li key={i} className="breadcrumb-item active"><Link to="#">{text}</Link></li>)
        } else {
            html.push(<li key={i} className="breadcrumb-item"><Link to={link}>{text}</Link></li >)
        }
    }

    return html;
}

export default class Crumbs extends React.Component {
    render() {
        return (
            <ol className="usa-unstyled-list usa-nav-secondary-links" style={{ marginTop: "12px" }}>
                {c(this.props.crumbs)}
            </ol>
        )
    }
}

