import DBRAW from "./database.json";

class database
{
    constructor(filename)
    {
        this.filename = filename;
        this.database = DBRAW;
    }   
    getAudiences()
    {
        return this.database.Audiences;
    }
    getAudience(audience)
    {
        return this.database.Audiences.filter(a => a.id == audience)[0];
    }
    getResources(audience,category)
    {
        //Get unique by title
        if(audience === "*" && category === "*")
        {
            let titles = {};
            for(let i in this.database.Resources)
                titles[this.database.Resources[i].title] = this.database.Resources[i];
            
            let res = [];
            for(let i in titles)
                res.push(titles[i])

            return res.sort( (i,j) => i.title - j.title);;
        }
        return this.database.Resources.filter( i => i.audience === audience && i.category === category).sort((a,b) => (a.order || a.title > b.order || b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
    }
    getHeader(audience,category)
    {
        return this.database.Headers.filter( i => i.audience === audience && i.category === category)[0];
    }
    getCategory(audience,id)
    {
        return this.database.Categories.filter( i => i.audience === audience && i.id == id)[0]
    }
    getCategories(audience)
    {
        return this.database.Categories.filter( i => i.audience === audience).sort((a,b) =>  {
            if(a.order && b.order)
                return a.order-b.order;
            return (a.linkText > b.linkText) ? 1 : ((b.linkText > a.linkText) ? -1 : 0)
        });
    }

}

export default new database();